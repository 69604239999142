const buttons = {
  'Recovery button': 'Recovery',
  'Change button': 'Change',
  'Back button': 'Back',
  'Create button': 'Create',
  'Save button': 'Save',
  'Update button': 'Update',
  'Delete button': 'Delete',
  'Search button': 'Search',
  'Apply button': 'Apply',
  'Clear button': 'Clear',
  'Copy button': 'Copy',
  'Cancel button': 'Cancel',
  'Ok button': 'Ok',
  'Back to Home': 'To main page',
  'status-city': 'Info',
  'cancel-city': 'Cancel',
  'register-city': 'Register',
  'Create transaction confirmation': 'Create transaction confirmations'
};

const menu = {
  'Transaction menu': 'Transactions',
  'Users menu': 'Users',
  'Role menu': 'Roles',
  'User Item': 'User info',
  'Banks menu': 'Banks',
  'Flow menu': 'Flows',
  'Gateway menu': 'Gateway',
  'Cascading menu': 'Cascad',
  'Cascading rules menu': 'Rules',
  'Cascading models menu': 'Models',
  'Terminals menu': 'Terminals',
  'Merchant menu': 'Merchants',
  'Description menu': 'Export docs',
  'Description menu custom': 'Export',
  'Description menu city24': 'Export city24',
  'Codes menu': 'Codes',
  'Bin menu': 'Bin',
  'Reconciliation menu': 'Reconciliation'
};

const text = {
  'Having an account': 'I already have an account',
  'Reset account password': 'Restore access',
  'Success update': 'Entry updated',
  'Success upload': 'Файл загружен',
  'Do you want to remove': 'Do you want to delete the entry? ',
  'Register account': 'Registration',
  'Success registration': 'You are successfully registered',
  'Success deleted': 'Entry deleted',
  'Reset title': 'Restore password',
  'Success recovery send token': 'Instructions sent to email',
  Filter: 'Filter',
  reconciliationId: 'Reconciliation ID',
  reconciliationJobName: 'Reconciliation Type',
  createOn: 'Date',
  merchantId: 'Merchant',
  fieldName: 'Reconciliation Field',
  reason: 'Status',
  reference: 'Field Value',
  originalField: 'System Value',
  reportField: 'Report Value',
  resolved: 'Fixed',
  reconciliationResult: 'Reconciliation result',
  Confirm: 'You confirm the action ?',
  'File upload': 'File uploaded successfully',
  'HCB BALANCE': 'HomeCreditBank corporate account balance',

  nonExistent: 'Non-existent in software',
  missing: 'Missing in report',
  amount: 'Amount',
  resp_code: 'Status (code)',
  duplicate: 'Duplicate',
  'Total Amount Report': 'Total Amount Report',
  'View results': 'View results',
  new: 'New',
  loaded: 'Loaded',
  error: 'Error',
  validation_error: 'Validation Error',
  processing: 'Processing',
  processed: 'Processed',
  'Upload Files': 'Uploading a file',
  'Reconciliation type': 'Reconciliation type',

  'Number of report lines': 'Number of report lines',
  'Number of rows affected': 'Number of rows affected',
  'Total number of rows processed': 'Total number of rows processed',

  'Transactions List': 'Transactions list',
  'Transactions Confirmation': 'Transactions Confirmation',
  'Transactions Item': 'Transaction info',

  'Users List': 'Users list',
  'User Item Update': 'User update',
  'User Item Create': 'Creating a user',

  'Roles List': 'Role list',
  'Role Item Create': 'Creating a role',
  'Role Item Update': 'Role update',

  'Terminals List': 'Terminal list',
  'Terminals Item Id': 'Terminal info',
  'Terminals Model Create': 'Creating new terminal',
  'Terminals Token Update': 'Keys update',
  'Terminals Item Update': 'Terminal update',
  'Terminal Item Id': 'Terminal info',
  'Do you want to update token': 'Do you really want to update a key?',

  'Merchant List': 'Merchants list',
  'Merchant Item Id': 'Merchant description',
  'Merchant Item Update': 'Merchant update',
  'Merchant Item Create': 'Creating a merchant',
  'Merchant Token Update': 'Merchant key update',

  'Banks List': 'Banks list',
  'Banks Item Id': 'Bank information',
  'Bank Item Create': 'Adding a new bank',
  'Bank Item Update': 'Bank update',
  'Bank Deposit Update': 'Limit update',

  'Transactions Flow List': 'Transaction schemes list',
  'Transactions Flow Create': 'Create a transaction schema',

  'Gateway List': 'Gateway list',
  'Gateway Item Create': 'Creating a gateway',
  'Gateway Item': 'Gateway description',
  'Gateway Item Update': 'Gateway update',

  'Cascading Rules List': 'Rules list',
  'Cascading Models List': 'Model List',
  'Cascading Model Create': 'Creating a model',

  'Codes List': 'Codes list',
  'Codes Item Id': 'Code information',
  'Code Item Update': 'Code update',
  'Code Item Create': 'Creating the code',
  'Transactions Logs Item': 'Transaction logs',
  'Reconciliation List': 'Reconciliation',
  miss_in_bnk: 'Missing from the bank',
  miss_in_pt: 'Missing from the pytech',
  trn_not_registered: 'Not registered',
  miss_in_bnk2: 'Attention!!!',
  dateStart: 'Period from',
  dateEnd: 'Period to',
  tranTypeId: 'Type of operation',
  bankId: 'Bank',
  respCode: 'Status',
  amountFrom: 'Amount from',
  amountTo: 'Amount to',
  'card first 6 number': 'First 6 digits of the card',
  'card last 4 number': 'The last 4 digits of the card',
  tranId: 'Transaction ID',
  gateway: 'Bank',
  acsStatus: '3DS status',
  approval: 'Authorization code',
  callbackUrl: 'Call back URL',
  fee: 'Fee',
  minFee: 'Min Fee',
  getewayRefNo: 'Bank ID',
  lang: 'Language',
  pan: 'Map',
  respCodeId: 'Response code',
  rrn: 'RRN',
  tranType: 'Type of operation',
  description: 'Description',
  status: 'Status',
  timezone: 'Time zone',
  comment: 'Comments',
  bankName: 'Bank',
  fields: 'Other fields',
  langEn: 'EN',
  langRu: 'RU',
  langUk: 'UA',
  external: 'Code',
  merchId: 'Merchant',
  Profile: 'Profile',
  Settings: 'Settings',
  Logout: 'Logout',
  Balance: 'Balance',
  fixed: 'To correct',

  'Export List': 'Экспорт файлов',
  'Download File': 'Download File',
  generated_reports_list: 'List of available files',

  'Bin List': 'Bin',
  'Invitation link': 'Ссылка для приглашения пользователя',
  'City24 Files List': 'Export city24 files',
  'Success tab': 'Успешные',
  'Failed tab': 'Отклоненные',
  'Conversion tab': 'Конверсия',

  'Password was successfully changed': 'Пароль был успешно изменен',
  'Reset success send':
    'Инструкция по восстановлению пароля была отправлена на указанный email',
  'Request success send': 'Request success send',
  status_new: 'New',
  status_processing: 'Processing',
  status_finished: 'Finished',
  status_failed: 'Failed',
  'Create company': 'Create company',
  'Update company': 'Edit company',
  'Transit account': 'Transit account',
  'Transaction prefix': 'Transaction prefix',
  startDate: 'Period start date and time',
  endDate: 'Period end date and time',
  'Type and press Enter': 'Type and press Enter',

  infoTranid:
    'If the "transaction prefix" is selected, when entering the "Transaction ID", the transaction number should be added without the "kassa24" or "qiwi" prefix. If the "transaction prefix" is not selected, the search will be performed by exact match with the full "Transaction ID" value.',

  name: 'Company name',
  iin: 'IIN/BIN',
  bik: 'BIK',
  panHash: 'Hash cards',
  panMask: 'Card mask',
  bankAccount: 'Company bank account',
  balance: 'Balance',
  account: 'Transit account Id',
  targetAccount: 'Company bank account',
  code: 'Beneficiary code',
  knp: 'KNP',
  statusName: 'Status description',
  narrative: 'Comment',
  'Transit Account': 'Transit account id',
  transport: 'Transit account bank name',
  updateStatus: 'Status updated',
  refresh: 'Refresh',
  'Create transactions': 'Create transactions',
  'Delete entry': 'Post deleted',
  'Select Company': 'Select Company',
  'Select Account': 'Transit account',
  'Create Transactions': 'Create transaction',
  Success: 'Success',
  'Do you confirm the action?': 'Do you confirm the action?',
  'Handbook Companies': 'Handbook Companies',
  'Transit transactions': 'Transit transactions',
  knpError: 'KNP consists from 3 digits',
  'The entered amount exceeds the available balance':
    'The entered amount exceeds the available balance',
  nameFormat: 'Allowed number of characters 2–100',
  invalidBankAccountFormat: ' Invalid format',
  iinError: 'Iin consists from 12 digits',
  'Update Status': 'Update Status',
  max2digits: 'Beneficiary code consists from 2 digits',
  'Send to Bank': 'Send to Bank'
};

const fields = {
  orderBy: 'Order by',
  sortAscending: 'Ascending',
  sortDescending: 'Descending',
  uuid: 'ID',
  name: 'name',
  createOn: 'created by',
  merchant: 'merchant',
  tranId: 'transaction id',
  tranType: 'type of operation',
  pan: 'card number',
  fee: 'commission',
  gateway: 'acquirer',
  respCode: 'response code',
  lang: 'language',
  editOn: 'Updated',
  firstName: 'name',
  lastName: 'surname',
  phone: 'phone number',
  loginTries: 'login attempts',
  lastLogin: 'login date',
  linkToken: 'key',
  role: 'role',
  'email table': 'Email',
  field_email: 'Email',
  'role name field': 'role',
  'permissions field': 'permissions',
  'name bank field': 'bank name',
  depositLimit: 'deposit limit',
  keyToken: 'keys',
  flowName: 'flow name',
  endpoint: 'entry point',
  env: 'environment',
  bank: 'bank',
  'name gateway field': 'gateway name',
  'Select value': 'select a value',
  type_operation: 'Type of transaction',
  notificationChannel: 'Notification channel',
  'Set deposit limit': 'Set limit',
  'Increase deposit limit': 'Increase limit',
  'Decrease deposit limit': 'Decrease limit',
  'Comment show': 'Show comment',
  Loading: 'Loading',
  'Upload file': 'Upload file',
  cityRespCode: 'City24 status',
  company_email: 'Company Email',
  company_id: 'Company ID',
  frequency: 'frequency',
  startedAt: 'started At',
  finishedAt: 'finished At',
  status: 'status'
};

const server = {
  'Token error': 'Token error',
  '404 error title': 'Page not found',
  '404 error description': "Sorry, that page can't be found"
};

const validationForm = {
  required: 'required',
  email: 'email',
  'field float': 'Поле должно быть целым или дробным числом',
  'field number': 'Поле должно быть целым числом',
  'Passwords must match': 'Passwords must match',
  'Error password format':
    'The password must be at least 8 characters long. Use uppercase and lowercase letters as well as numbers and special characters',
  'Error phone format': 'Phone must be in 380XXXXXXXXXXX format'
};

export const en = {
  'Forgot password': 'Forgot password',
  'Create new account': 'Create new account',
  'Login text': 'Login',
  ...buttons,
  ...fields,
  ...text,
  ...validationForm,
  ...menu,
  ...server
};
